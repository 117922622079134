import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { ToastService } from '@sitemule/ng-components/components/toast';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BasketService } from './basket.service';
import { FavoriteProductsService } from './favorite-products.service';

interface User {
  id: string;
  name: string;
  email: string;
  language: string;
  loginId: string;
  company?: {
    erp_id: string;
    name: string;
    cvr: string;
    primaryAddress?: {
      isPrimary: boolean;
      type: 'billing' | 'delivery';
      name: string;
      address1: string;
      city: string;
      postalCode: string;
    };
    addresses?: {
      isPrimary: boolean;
      type: 'billing' | 'delivery';
      name: string;
      address1: string;
      city: string;
      postalCode: string;
    }[];
  };
  stats: {
    salesAmountYTDtd: number;
    salesAmountPeriod: number;
  };
  salesPerson?: {
    email: string;
    image: string;
    name: string;
    header: string;
    phone: string;
    mobile: string;
    linkedIn: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly basketService = inject(BasketService);
  private readonly favoriteProductsService = inject(FavoriteProductsService);
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {}

  public readonly loggedInUser = signal<User | undefined>(undefined);
  public readonly isLoggedIn = computed(() => {
    return !!this.loggedInUser();
  });

  public load(): Observable<{
    loggedIn: boolean;
  }> {
    return this.http
      .get<
        | {
            loggedIn: false;
          }
        | {
            loggedIn: true;
            user_id: number;
            user: {
              email: string;
              name: string;
              language: string;
              profile_id: string;
            };
            customer: {
              name: string;
              cvr: string;
              erp_id: string;
              addresses?: {
                type: string;
                name: string;
                primary_address: boolean;
                address_1: string;
                zipcode: string;
                city: string;
              }[];
            };
            salesPerson?: {
              email: string;
              image: string;
              name: string;
              header: string;
              phone: string;
              mobile: string;
              linkedIn: string;
            };
            stats?: {
              sales_amount_ytd?: number;
              sales_amount_period?: number;
            };
          }
      >('@api/cms/usr/getCurrentClient')
      .pipe(
        switchMap(res => {
          if (res.loggedIn) {
            this.basketService.loadBasketIfNotLoaded();
            return this.favoriteProductsService.getProductsFromFavList().pipe(map(() => res));
          }
          return of(res);
        }),
        tap(res => {
          if (res.loggedIn) {
            const addresses = res.customer.addresses?.map(add => {
              return {
                isPrimary: add.primary_address,
                type: add.type.trim().toLowerCase() === 'billing' ? ('billing' as const) : ('delivery' as const),
                name: add.name,
                address1: add.address_1,
                city: add.city,
                postalCode: add.zipcode,
              };
            });
            this.loggedInUser.set({
              id: `${res.user_id}`,
              name: res.user.name,
              email: res.user.email,
              language: res.user.language,
              loginId: res.user.profile_id,
              company: {
                erp_id: res.customer.erp_id,
                name: res.customer.name,
                cvr: res.customer.cvr,
                primaryAddress: addresses?.find(add => add.isPrimary && add.type === 'billing'),
                addresses,
              },
              salesPerson: res.salesPerson
                ? {
                    ...res.salesPerson,
                    image: res.salesPerson.image ? `${environment.apiDomain}/${res.salesPerson.image}` : '',
                  }
                : undefined,
              stats: {
                salesAmountPeriod: res.stats?.sales_amount_period || 0,
                salesAmountYTDtd: res.stats?.sales_amount_ytd || 0,
              },
            });
          } else {
            this.loggedInUser.set(undefined);
          }
        }),
        catchError(err => {
          this.loggedInUser.set(undefined);
          return throwError(() => err);
        })
      );
  }

  public create(value: {
    firstName: string;
    lastName: string;
    companyName: string;
    cvr: string;
    tel: string;
    email: string;
    address: string;
    postalCode: string;
    city: string;
    termsAndCondition: boolean;
    newsletterSubscription: boolean;
  }) {
    return this.http.post(`@api/cms/cmn/notifications`, {
      ...value,
      mailType: 'requestAccount',
    });
  }
  public requestForgotPassword(username: string) {
    return this.http.post<void>('@api/cms/user/forgotPassword', {
      username,
    });
  }
  public resetPassword(code: string, password1: string, password2: string) {
    return of(undefined);
  }

  public login(username: string, password: string) {
    return this.http
      .post<{
        success: boolean;
      }>('@api/cms/user/login', {
        clientid: username,
        password: password,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 403:
            case 404:
              this.toastService.push({
                title: 'Login failed',
                autoHide: true,
                type: 'warning',
              });
              return throwError(() => error);
            default:
              this.toastService.push({
                title: error.message,
                autoHide: true,
                type: 'warning',
              });
              return throwError(() => error);
          }
        }),
        switchMap(res => {
          if (res.success) {
            return this.load();
          }
          return of({
            loggedIn: false,
          });
        })
      );
  }

  public logout(): Observable<void> {
    return this.http.post<void>('@api/cms/user/logoff', {}).pipe(
      tap(() => {
        this.loggedInUser.set(undefined);
      })
    );
  }

  public changePassword(oldPassword: string, newPassword: string) {
    const userId = this.loggedInUser()?.id;
    return this.http.post<{
      success: boolean;
      message?: string;
    }>(`@api/mnu/usr/changePassword/${userId}`, {
      oldPassword: oldPassword,
      newPassword: newPassword,
    }); // TODO: This service fails
  }

  public setNewPassword(token: string, password: string) {
    return this.http.post('@api/mnu/usr/setNewPassword/' + token, {
      newPassword: password,
    });
  }

  public forgotPassword(username: string) {
    return this.http.post<void>('@api/cms/user/forgotPassword', { username });
  }

  public doesEmailExist(email: string) {
    return this.http
      .post<{
        success: boolean;
      }>('@api/mnu/usr/validateUser', {
        profile_id: email,
        name: 'Your name',
      })
      .pipe(
        map(res => {
          return !res.success;
        })
      );
  }
}
