import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { IBasket, IBasketItemWithProduct, IBasketResponse } from './basket.service';
import { ProductInventoryStatus } from './icebreak-webshop.interface';
import { WebshopService } from './icebreak-webshop.service';
import { addLanguageKeyInUrl } from './menu.service';

enum OrderStatus {
  deleted = 0,
  draft = 10,
  offer = 20,
  basket = 30,
  ordered = 40,
  completed = 50,
  invoiced = 60,
  cancelled = 90,
}
interface IOrderGroupResponse {
  items?: IOrderResponse[];
}

interface IOrderResponse extends IBasketResponse {
  order_status: OrderStatus;
  orderNumber: string;
  order_status_description: string;
  order_date: string;
}

export interface IOrder extends IBasket {
  status: OrderStatus;
  orderNumber: string;
  statusDescription: string;
  orderDate: string;
}

const orderResponseToOrder = (order: IOrderResponse): IOrder => {
  return {
    id: `${order.order_id}`,
    status: order.order_status,
    orderNumber: order.orderNumber,
    statusDescription: order.order_status_description,
    orderDate: order.order_date,
    totalProductGross: order.total_net_amount + order.total_discount_amount,
    totalProductNet: order.total_net_amount,
    totalDiscount: order.total_discount_amount,
    totalFreight: order.total_freight_amount,
    totalVATBasis: order.total_vat_basis,
    totalVAT: order.total_vat,
    totalPayableAmount: order.total_total_order,
    deliverAtWarehouse: !!order.properties?.deliverAtWarehouse,
    deliverAtMyCompanyAddress: !!order.properties?.deliverAtMyCompanyAddress,
    requestForOffer: !!order.properties?.offer,
    deliveryAddress:
      order.delivery_address_1 || order.delivery_zipcode || order.delivery_city
        ? {
            address: order.delivery_address_1,
            postalCode: order.delivery_zipcode,
            city: order.delivery_city,
          }
        : undefined,
    billingAddress:
      order.billing_address_1 || order.billing_zipcode || order.billing_city
        ? {
            address: order.billing_address_1,
            postalCode: order.billing_zipcode,
            city: order.billing_city,
          }
        : undefined,
    additionalInformation: order.properties?.additionalInformation,
    items: [],
  };
};

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private readonly http = inject(HttpClient);
  private readonly translateService = inject(TranslateService);
  private readonly webshopService = inject(WebshopService);

  public getMyOrders(): Observable<IOrder[]> {
    return this.http.get<IOrderGroupResponse[]>('@api/cms/ord/ordersByCustomer').pipe(
      // Filter out groups without items and Flat orders into one array
      map(ordersGroups => {
        return ordersGroups.reduce((acc, orderGroup) => {
          return [...acc, ...(orderGroup.items || [])];
        }, [] as IOrderResponse[]);
      }),
      // Map to internal model
      map(orders => {
        return orders.map(orderResponseToOrder);
      })
    );
  }

  public getOrderLines(orderId: string): Observable<IBasketItemWithProduct[]> {
    return this.http
      .get<
        {
          order_line_id: number;
          quantity: number;
          price: number;
          product: {
            product_id: number;
            seourl: string;
            image: string;
            title: string;
            subLabel: string; // TODO: this is missing in response
            productNumber: string;
            uom: string; // TODO: MIR
          };
          inventory: {
            inventoryStatus: ProductInventoryStatus;
          };
          minimumOrderQuantityText: string;
          unitQuantity: number;
          customerPrice: {
            unitNormalPrice: number;
            unitYourPrice: number;
            linePrice: number;
            salesPrice: number;
          };
        }[]
      >(`@api/cms/ord/order/${orderId}/lines`)
      .pipe(
        map(items =>
          items.map(i => {
            return {
              id: `${i.order_line_id}`,
              quantity: i.quantity,
              price: i.customerPrice.linePrice,
              unitYourPrice: i.customerPrice.unitYourPrice,
              unitNormalPrice: i.customerPrice.unitNormalPrice,
              unitQuantity: i.unitQuantity,
              product: {
                id: `${i.product.product_id}`,
                seourl: i.product.seourl,
                url: addLanguageKeyInUrl(
                  i.product.seourl,
                  this.translateService.currentLang,
                  this.translateService.getLangs()
                ),
                unitOfMeasurement: i.product.uom,
                title: i.product.title,
                subTitle: i.product.subLabel,
                inventoryStatus: i.inventory.inventoryStatus,
                productNumber: i.product.productNumber,
                price: i.customerPrice.salesPrice,
                pricePerUnitOfMeasurement: i.customerPrice?.unitYourPrice,
                minimumOrderQuantityText: i.minimumOrderQuantityText,
                assets: [
                  {
                    type: 'image',
                    link: i.product.image,
                    alt: i.product.title,
                  },
                ],
              },
            };
          })
        )
      );
  }
}
